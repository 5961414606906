import { Typography } from "@mui/material";
import React, { useContext } from "react";
import AppContext from '../../Context/Context';

function ResponseComponent() {
  const { responseContent} = useContext(AppContext);
  console.log(responseContent);

  let conceptsItems = null;

  if (responseContent.content.concepts && Object.keys(responseContent.content.concepts).length !== 0) {
    conceptsItems = responseContent.content.concepts.map((concept) => {
      // Split the explanation into separate sentences
      const sentences = concept.concept_explanation.split('.');
      
      return (
        <div key={concept.concept_title}>
          <Typography variant="h5" align="left" >{concept.concept_title}</Typography>
          {
            // Map over the sentences and create a new Typography component for each one
            sentences.map((sentence, index) => {
              // Only add the sentence if it is not an empty string
              if (sentence.trim() !== '') {
                return <Typography variant="body1" align="left" key={index}>{sentence.trim()}.</Typography>
              } else {
                return null;
              }
            })
          }
        </div>
      );
    });
  }

  return (
    <div className="response-component">
      <Typography variant="h3" >{responseContent.content.topic}</Typography>
      <Typography variant="body1" >{responseContent.content.first_principle_exp}</Typography>
      <hr/>
      <div className="response-box">{conceptsItems}</div>
    </div>
  );
}

export default ResponseComponent;
