import React, { useState,useContext } from "react";
import Select from '@mui/material/Select';
import { MenuItem, MenuList } from "@mui/material";
import MyContext from '../../Context/Context';

const userDomains= [
    { value: "Architecture" },
    { value: "Civil Engineering" },
    { value: "Electrical Engineering" },
    { value: "Mechanical Engineering" },
    { value: "Aerospace Engineering" },
    { value: "Chemical Engineering" },
    { value: "Information Technology" },
    { value: "Accounting" },
    { value: "Finance" },
    { value: "Marketing" },
    { value: "Law" },
    { value: "Medicine" },
    { value: "Biology" },
    { value: "Chemistry" },
    { value: "Education" },
    { value: "Journalism" },
    { value: "Agriculture" },
    { value: "Psychology" },
    { value: "Design" },
    { value: "Music" },
    { value: "Pop culture" },
    { value: "Government and Politics" },
    { value: "Sports" },
    { value: "Hospitality" }
  ]
function UserDomainComponent() {
    const { selectedDomain, setSelectedDomain } = useContext(MyContext);
    async function userDomainChange(e) {
        setSelectedDomain(e.target.value);
    }
    return (
      <div>
        <Select fullWidth onChange={userDomainChange} defaultValue={"Pop culture"}>
          
            {userDomains.map((userDomain) => (
                <MenuItem key={userDomain.value} value={userDomain.value}> {userDomain.value} </MenuItem>))
                }
        </Select>
      </div>
    );
}
export default UserDomainComponent;