import React, { useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import MyContext from '../../Context/Context';
import {Navigate} from "react-router-dom";
//import FidgetSpinner from "react-loader-spinner";

function QueryComponent(userId) {
    const [inputValue, setInputValue] = useState("");
    const { user } = userId;
  // const [loading, setLoading] = useState(false);
  // const [responseData, setResponseData] = useState(null);

  const { renderResponse, setRenderResponse, responseContent, setResponseContent, selectedDomain, setSelectedDomain, renderLoading,setRenderLoading, token } = useContext(MyContext);

  async function getContent(topic,domain) {
    
    const url = "https://platosparallel.com/api/v1/content";
    try {
      let apiResponse = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          topic: topic,
          userAssociation: domain,
             userId : user,
        }),
        mode: "cors",
      });
      //handler for unsuccessful fetch
      // if (!apiResponse.ok) {
        // redirect to error page when unauthorized
        console.log(apiResponse.status);
        if (apiResponse.status === 401) {
          //redirect("/unauthorized");
         // <Navigate to="/unauthorized" />
        }
      // }
      else{let jsonResponse = await apiResponse.json();
    //   console.log(JSON.stringify({
    //     "topic": "Modern Electric Cars",
    //     "keywords": [
    //         "Electric",
    //         "Cars",
    //         "Pop Culture",
    //         "Analogy"
    //     ],
    //     "concepts": [
    //         {
    //             "concept_title": "Electric Cars as Superheroes",
    //             "concept_explanation": "Electric cars can be compared to superheroes who possess extraordinary powers. Just as superheroes use their powers to fight against evil forces, electric cars use their electric power to fight against air pollution and climate change. They are like the Avengers, saving the planet one ride at a time."
    //         },
    //         {
    //             "concept_title": "Electric Cars as Fashion Accessories",
    //             "concept_explanation": "Electric cars are like fashion accessories that not only look good on you, but are also good for the environment. Just as wearing designer clothes makes a statement about your style, driving an electric car makes a statement about your values. Think of electric cars as the Tesla of the fashion world."
    //         },
    //         {
    //             "concept_title": "Electric Cars as Gaming Consoles",
    //             "concept_explanation": "Electric cars can be compared to gaming consoles, with their advanced technology and sleek designs. Just as gaming consoles offer players a next-level gaming experience, electric cars offer drivers a next-level driving experience with their quiet motors, instant torque, and advanced entertainment systems. They are like the Xbox or PlayStation of the automotive industry."
    //         }
    //     ]
    // }));
    //   setResponseContent({"content":{
    //     "topic": "Modern Electric Cars",
    //     "keywords": [
    //         "Electric",
    //         "Cars",
    //         "Pop Culture",
    //         "Analogy"
    //     ],
    //     "concepts": [
    //         {
    //             "concept_title": "Electric Cars as Superheroes",
    //             "concept_explanation": "Electric cars can be compared to superheroes who possess extraordinary powers. Just as superheroes use their powers to fight against evil forces, electric cars use their electric power to fight against air pollution and climate change. They are like the Avengers, saving the planet one ride at a time."
    //         },
    //         {
    //             "concept_title": "Electric Cars as Fashion Accessories",
    //             "concept_explanation": "Electric cars are like fashion accessories that not only look good on you, but are also good for the environment. Just as wearing designer clothes makes a statement about your style, driving an electric car makes a statement about your values. Think of electric cars as the Tesla of the fashion world."
    //         },
    //         {
    //             "concept_title": "Electric Cars as Gaming Consoles",
    //             "concept_explanation": "Electric cars can be compared to gaming consoles, with their advanced technology and sleek designs. Just as gaming consoles offer players a next-level gaming experience, electric cars offer drivers a next-level driving experience with their quiet motors, instant torque, and advanced entertainment systems. They are like the Xbox or PlayStation of the automotive industry."
    //         }
    //     ]
    // }});
    setResponseContent(jsonResponse);
      setRenderLoading(false);
      //this.setState({ showResponse: true });
      setRenderResponse(true);
    }} catch (err) {
      console.error(err);
    }}
  

  async function handleEnterPress(e) {
    if (e.key === "Enter") {
      setRenderLoading(true);
      getContent(inputValue,selectedDomain)
    }
  }
  

  return (
    <div>
      <TextField
        fullWidth
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleEnterPress} label="What are you curious about?"
        autoFocus={true}
      />
    </div>
  );
}

export default QueryComponent;
