import React    from "react";
import template from "./LandingComponent.jsx";

class LandingComponent extends React.Component {
  render() {
    return template.call(this);
  }
}

export default LandingComponent;
