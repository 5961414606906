import React, { useEffect,useState } from "react";
import { Grid, Paper, Box, Avatar } from "@mui/material";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import QueryComponent from "../Components/QueryComponent/QueryComponent";
import ResponseComponent from "../Components/ResponseComponent/ResponseComponent";
import UserDomainComponent from "../Components/UserDomainComponent/UserDomainComponent";
import { makeStyles } from "@mui/styles";
import AppContext from "../Context/Context";

import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import supabase from "../utils/supabaseClient";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    //padding: theme.spacing.unit *12,
    textAlign: "center",
    //color: theme.palette.text.secondary,
  },
}));

const AppPage = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [renderResponse, setRenderResponse] = useState(false);
  const [renderLoading, setRenderLoading] = useState(false);
  const [responseContent, setResponseContent] = useState({});
  const [selectedDomain, setSelectedDomain] = useState("");
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState("");

  //   const handleResponse = (newData) => {
  //     setResponse(newData);
  useEffect(() => {
    
      updateUserData(setUserId);
    });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
async function updateUserData(setUserId) {
  const {data: { session },} = await supabase.auth.getSession()
  if (session){

    setUserId(session.user.id)
      // Get the current user metadata
      setToken(session.access_token);
      const currentMetadata = session.user.user_metadata || {};
      console.log(currentMetadata)
      // Check if the metadata has already been updated
      if (!currentMetadata.isUpdated) {
        // Extract Google's id token from the current session
        // const  id_token  = session.provider_token;
  
        // const claims = JSON.parse(atob(id_token.split('.')[1]));
        // Get the user's name and picture from the claims
        const { name, picture } = currentMetadata;
        
        setUser({ "name": name, "display_picture":picture })
        // Update the user's metadata with the name, picture, and update flag
        const {
          data: { userData },
          error,
        } = await supabase.auth.updateUser({
          data: {
            full_name: name,
            profile_pic_url: picture,
            isUpdated: true,
          },
        });
  
        if (error) {
          console.log('Error updating user metadata:', error);
        }
      }
  }
}


  //   };
  return (
    <div className={classes.root}>
      <AppContext.Provider
        value={{
          renderResponse,
          setRenderResponse,
          responseContent,
          setResponseContent,
          selectedDomain,
          setSelectedDomain,
          renderLoading,
          setRenderLoading,
          token,
          setToken,
          userId,
          setUserId,
          user,
          setUser,
        }}
      >
        <AppBar position="static">
          <Toolbar color="#">
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              Plato's Parallel
            </Typography>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>Sign out</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Grid container rowSpacing={1} padding={3} spacing={2}>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              <UserDomainComponent />
            </Paper>
          </Grid>
          <Grid item md={7} xs={12}>
            <Paper className={classes.paper}>
              {/* Render a brief description of the  domain */}
            </Paper>
          </Grid>

          <Grid item sx={{ display: { xs: "none", md: "block" } }} md={2}>
            <Paper className={classes.paper}>25% width</Paper>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} padding={3} spacing={2}>
          <Grid item md={7} xs={12}>
            <Paper className={classes.paper}>
              <QueryComponent user={userId}/>
            </Paper>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} padding={2} spacing={2} marginLeft={0}>
          <Grid item md={10} xs={12} padding={4}>
           
              {renderLoading && (
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" gutterBottom align="center">
                    Loading...
                  </Typography>
                </Box>
              )}
              {renderResponse &&  <Paper className={classes.paper} elevation={2} style={{ padding: '20px' }}> <ResponseComponent /> </Paper>}
          </Grid>
        </Grid>
        {/* <Paper elevation={3} square style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <Typography variant="h6" gutterBottom align="center">
            
        </Typography>
        </Paper> */}
      </AppContext.Provider>
    </div>
  );
};

export default AppPage;
