import React,{useEffect} from 'react';
import { withTheme,createTheme,ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {BrowserRouter as Router,Routes, Route, Outlet, Link, useNavigate} from 'react-router-dom';
import AppPage from './Pages/AppPage';
import supabase from './utils/supabaseClient';
import LandingComponent from './Components/LandingPage'
import Unauthorized from './Components/Unauthorized/Unauthorized'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    //padding: theme.spacing.unit *12,
    textAlign: 'center',
    //color: theme.palette.text.secondary,
  },
}));

const customTheme = {
    MuiAppBar: {
      colorPrimary: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      },
    },
};
const theme = createTheme({
    palette: {
      mode: 'light',
      background: {
        default:'linear-gradient(to top, #c71d6f 0%, #d09693 100%);',
      },
      primary: {
        main: '#262727',
        light: '#ffffff',
        dark: '#000000',
      },
      secondary: {
        main: '#d81b60',
      }
    },
    typography: {
      body1: {
        fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        // to make padding responsive to screen
        padding: '0.5rem',
      },
    }
    
  });
function App() {
  const history = useNavigate();
  const classes = useStyles();
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
          history('/app')
      } else {
        history('/')
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [history]);
  return (
    <div className={classes.root}>
     <ThemeProvider theme={theme}>
    <Routes>
        <Route path="/app" element={<AppPage />} />
        <Route path="/" element={<LandingComponent />} />
        <Route path="/unauthorized" element={Unauthorized} />
    </Routes>
    </ThemeProvider>
  </div>
  );
}

export default App;
