import { Button, Typography, Box, Grid } from "@mui/material";
import React from "react";
import supabase from "../../utils/supabaseClient";
import landing1 from "./img/landing_1.png";
import  landing2 from "./img/landing_2.png";
import landing3 from "./img/landing_3.png";

const Landing = () => {
  return (
    <Box  sx={{
      p: { xs: 5, sm: 10, md: 15, lg: 20 }, 
      ml: { xs: 1, sm: 2, md: 3, lg: 4 }
    }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography variant="h2">Platos Parallel</Typography>
          <Typography variant="subtitle1">Unleash Your Inner Einstein!</Typography>
        </Grid>
        <Grid xs={12} md={6} sx={{
          p: { xs: 1, sm: 2, md: 3, lg: 4 }, 
          m: { xs: 1, sm: 2, md: 3, lg: 4 }
        }}>
          <Typography variant="body1">
            Welcome to Plato's Parallel, the app that makes learning complex
            concepts and topics a breeze! Our app offers a unique approach to
            learning with analogies to explain difficult ideas in a simple,
            relatable way Ever felt like Newton under the apple tree? Experience
            that "Aha!" moment every day with us!
          </Typography>
        </Grid>
        <Grid item md={4}>
          <img src= {landing1} alt="landing" height={300} width={300} />
          </Grid>
        <Grid xs={12}>
          <Typography variant="h3">
            It's No Longer Rocket Science
          </Typography>
        </Grid>
        <Grid item md={4}>
          <img src= {landing2} alt="landing" height={300} width={300} />
          </Grid>
        <Grid item xs={12} md={6} sx={{
          p: { xs: 1, sm: 2, md: 3, lg: 4 }, 
          m: { xs: 1, sm: 2, md: 3, lg: 4 }
        }}>
        <Typography variant="subtitle1">
          From the innards of quantum physics to the majesty of Renaissance art,
          we've got you covered. With our unique analogy-based learning, you
          don't need to be an Einstein to understand E=mc^2. You'll just need to
          be, well, you!
        </Typography>
    </Grid>
    <Grid xs={12}> <Typography variant="h3">The Red Carpet to Wisdom</Typography></Grid>
        <Grid xs={12} md={6} sx={{
          p: { xs: 1, sm: 2, md: 3, lg: 4 }, 
          m: { xs: 1, sm: 2, md: 3, lg: 4 }
        }}>
        <Typography variant="subtitle1">
          Think of our app as your personal brain gym - except, there's no heavy
          lifting. Here's how you can beef up those brain muscles:
          <ol>
            <li>
              Pick Your Comfort Zone - Select an area of expertise or interest.
              It can be as quirky as your grandma's secret cookie recipe, or as
              serious as rocket science.
            </li>
            <li>
              Uncover New Concepts - Our intelligent system bridges the gap
              between the familiar and the unknown.
            </li>
            <li>
              Eureka! - Grasp new concepts faster than you can say
              "Supercalifragilisticexpialidocious." Our analogies give a fun
              twist to learning. Prepare for some major light bulb moments!
            </li>
          </ol>
        </Typography>
      </Grid>
      <Grid md={4}>
          <img src= {landing3} alt="landing" height={300} width={300} />
          </Grid>
      <Grid xs={12}>
        <Button variant="contained" color="secondary" onClick={signInWithGoogle}>
          Hop On The Knowledge Train
        </Button>
      </Grid>
      </Grid>
    </Box>
  );
};

async function signInWithGoogle() {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: "google",
    redirectTo: "/app", // Redirect to /app after successful authentication
  });

  if (error) {
    console.error("Error signing in with Google:", error.message);
  }
}

async function signout() {
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.log("Error occurred " + error);
  }
}

export default Landing;
